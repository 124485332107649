"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSolutioTemplateRefFile = exports.isHintergrungRefFile = void 0;
function isHintergrungRefFile(refFile) {
    return !!(refFile === null || refFile === void 0 ? void 0 : refFile.startsWith('Hintergrund/'));
}
exports.isHintergrungRefFile = isHintergrungRefFile;
function isSolutioTemplateRefFile(refFile) {
    return !!(refFile === null || refFile === void 0 ? void 0 : refFile.startsWith('SolutioTemplates/'));
}
exports.isSolutioTemplateRefFile = isSolutioTemplateRefFile;
