"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.benchmarkConfigRaw = exports.getBenchmarkConfig = exports.extractBenchmarkConfigItemFields = exports.flattenBenchmarkConfigs = exports.benchmarkPatientSeitIntervalle = void 0;
const types_1 = require("../../../types");
const benchmark_config_hkp_1 = require("./benchmark.config.hkp");
const benchmark_config_leistungen_1 = require("./benchmark.config.leistungen");
const benchmark_config_papzr_1 = require("./benchmark.config.papzr");
const benchmark_config_patienten_1 = require("./benchmark.config.patienten");
const benchmark_config_termine_1 = require("./benchmark.config.termine");
const benchmark_config_tz_1 = require("./benchmark.config.tz");
const benchmark_config_umsatz_1 = require("./benchmark.config.umsatz");
const benchmark_config_behandlungen_1 = require("./benchmark.config.behandlungen");
const benchmark_config_tbv2_1 = require("./benchmark.config.tbv2");
const benchmark_config_stichworte_1 = require("./benchmark.config.stichworte");
const util_1 = require("../utils/util");
let endi = 0;
exports.benchmarkPatientSeitIntervalle = [
    { name: '< 1 Monat', key: '1monat', von: 0, bis: (endi += 30) }, // 1 monat
    { name: '1 - 6 Monate', key: '6monate', von: endi, bis: (endi += 5 * 30.5) }, // 1-6 Monate
    { name: '6 - 12 Monate', key: '1jahr', von: endi, bis: (endi += 6 * 30.5) }, // 6-12 Monate
    { name: '1 - 2 Jahre', key: '2jahre', von: endi, bis: (endi += 12 * 30.5) }, // 1-2 Jahre
    { name: '2 - 5 Jahre', key: '5jahre', von: endi, bis: (endi += 3 * 12 * 30.5) }, // 2-5 Jahre
    { name: '5 - 10 Jahre', key: '10jahre', von: endi, bis: (endi += 5 * 12 * 30.5) }, // 5-10 Jahre
    { name: '> 10 Jahre', key: 'mehrAls10jahre', von: endi, bis: (endi += 50 * 12 * 30.5) }, // >10 Jahre
];
function flattenBenchmarkConfigs(itemConfigs, agg = [], path = []) {
    if (itemConfigs && itemConfigs.length > 0) {
        for (const ic of itemConfigs) {
            agg.push(ic);
            ic.path = path;
            flattenBenchmarkConfigs(ic.childs, agg, [...path, ic.name]);
        }
    }
    return agg;
}
exports.flattenBenchmarkConfigs = flattenBenchmarkConfigs;
function extractBenchmarkConfigItemFields(item, includeSmartValueFields = false) {
    // extract item fields
    let fields = [];
    if (item.virtual) {
        let rgx = /b(?:\.\w*)+/g;
        let match = rgx.exec(item.value);
        while (match != null) {
            fields.push(match[0].replace('b.', ''));
            match = rgx.exec(item.value);
        }
    }
    else {
        fields.push(item.key);
    }
    if (includeSmartValueFields && item.smartValue) {
        let rgx = /b(?:\.\w*)+/g;
        let match = rgx.exec(item.smartValue);
        while (match != null) {
            fields.push(match[0].replace('b.', ''));
            match = rgx.exec(item.smartValue);
        }
    }
    // return field unique
    return [...new Set(fields)];
}
exports.extractBenchmarkConfigItemFields = extractBenchmarkConfigItemFields;
function getBenchmarkConfig(settings, topStichworte, env) {
    let rootItemRaw = benchmarkConfigRaw(settings, topStichworte);
    let rootItem = {
        key: 'root',
        name: rootItemRaw.name,
        trendType: types_1.IBenchmarkConfigTrendType.none,
        isHeader: rootItemRaw.isHeader,
        isRootNode: rootItemRaw.isRootNode,
        level: 0,
        childs: [],
    };
    rootItem.childs = createChilds(rootItemRaw, rootItem, 1, env, settings);
    setItemParent(rootItem);
    // show root item
    rootItem.visible = true;
    let isFirstItem = true;
    for (const item of rootItem.childs) {
        item.visible = isFirstItem;
        item.collapsed = false;
        isFirstItem = false;
    }
    return rootItem;
}
exports.getBenchmarkConfig = getBenchmarkConfig;
function createChilds(item, parent, level, env, settings, lastunit) {
    var _a;
    if (item && item.childs && Object.values(item.childs).length > 0) {
        let idx = 0;
        let childs = [];
        for (const key in item.childs) {
            if (Object.prototype.hasOwnProperty.call(item.childs, key)) {
                let rawItem = item.childs[key];
                if (!rawItem.disabled || !rawItem.disabled.includes(env)) {
                    let unit = rawItem.unit || item.unit || lastunit; // use parent unit as fallback
                    let unitString = (0, util_1.benchmarkUnitShortName)(unit);
                    const newItem = {
                        key,
                        trendType: rawItem.trendType || types_1.IBenchmarkConfigTrendType.biggerisbetter,
                        value: rawItem.value || (rawItem.virtual ? undefined : key), // virtual field must have value otherwise undefined
                        unit,
                        unitString,
                        name: rawItem.name,
                        shortName: rawItem.shortName,
                        virtual: rawItem.virtual,
                        smartValue: rawItem.smartValue,
                        smartUnit: rawItem.smartUnit,
                        formatHint: rawItem.formatHint,
                        help: rawItem.help,
                        isHeader: rawItem.isHeader,
                        isRootNode: rawItem.isRootNode,
                        isTab: rawItem.isTab,
                        noAvg: rawItem.noAvg,
                        collapsed: rawItem.collapsed,
                        visible: true, // everything is visible by default
                        classes: rawItem.classes,
                        dividedClasses: rawItem.dividedClasses,
                        debug: rawItem.debug,
                        hideWhenFiltered: rawItem.hideWhenFiltered,
                        canFilterByPatid: rawItem.canFilterByPatid,
                        color: rawItem.color,
                        level,
                        idx: level === 2 ? 1 : idx++, // top row should always be dark
                        noHistory: rawItem.noHistory,
                        beta: rawItem.beta,
                        proLevel: rawItem.proLevel || types_1.BenchmarkConfigProLevelType.NEWBIE,
                        excludeForPVS: rawItem.excludeForPVS,
                        childs: [],
                        parent,
                    };
                    newItem.childs = createChilds(rawItem, newItem, level + 1, env, settings, unit);
                    newItem.tab = findRootParentTab(newItem);
                    // check for pvs support
                    if (!((_a = newItem.excludeForPVS) === null || _a === void 0 ? void 0 : _a.includes(settings.pvs))) {
                        childs.push(newItem);
                    }
                }
            }
        }
        return childs;
    }
    return [];
    // return undefined;
}
function benchmarkConfigRaw(settings, topStichworte) {
    return {
        name: 'KPI',
        isHeader: true,
        isRootNode: true,
        childs: {
            [types_1.BenchmarkParts.PATIENTEN]: {
                name: 'Patienten',
                isHeader: true,
                isRootNode: true,
                visible: false,
                collapsed: false,
                isTab: true,
                childs: {
                    behandeltePatienten: (0, benchmark_config_patienten_1.benchmarkConfigPatienten)(),
                    papzr: (0, benchmark_config_papzr_1.benchmarkConfigPaPzr)(),
                    stichworte: (0, benchmark_config_stichworte_1.benchmarkConfigStichworte)(topStichworte),
                },
            },
            [types_1.BenchmarkParts.TERMINE]: {
                name: 'Termine',
                isHeader: true,
                isRootNode: true,
                visible: false,
                collapsed: false,
                isTab: true,
                childs: {
                    _termine: (0, benchmark_config_termine_1.benchmarkConfigTermine)(),
                    terminbuchv2: (0, benchmark_config_tbv2_1.benchmarkConfigTBv2)(),
                    terminzeiten: (0, benchmark_config_tz_1.benchmarkConfigTerminzeiten)(),
                },
            },
            [types_1.BenchmarkParts.UMSATZ]: {
                name: 'Umsatz',
                isHeader: true,
                isRootNode: true,
                visible: false,
                collapsed: false,
                isTab: true,
                childs: {
                    _umsatz: (0, benchmark_config_umsatz_1.benchmarkConfigUmsatz)(),
                    leistungen: (0, benchmark_config_leistungen_1.benchmarkConfigLeistungen)(),
                },
            },
            [types_1.BenchmarkParts.HKPS]: Object.assign(Object.assign({}, (0, benchmark_config_hkp_1.benchmarkConfigHKP)()), { isTab: true }),
            [types_1.BenchmarkParts.BEHANDLUNGEN]: Object.assign(Object.assign({}, (0, benchmark_config_behandlungen_1.benchmarkConfigBehandlungen)(settings.leistungen.implantat)), { isTab: true }),
        },
    };
}
exports.benchmarkConfigRaw = benchmarkConfigRaw;
function setItemParent(item) {
    if (item.childs) {
        for (const c of item.childs) {
            c.parent = item;
        }
    }
}
function findRootParentTab(item) {
    if (item.isTab) {
        return item.key;
    }
    if (item.parent) {
        return findRootParentTab(item.parent);
    }
    return undefined;
}
